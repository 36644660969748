import { Drawer, makeStyles } from "@material-ui/core"
import React from "react"
import EditCourt from "./EditCourt"
import NewCourt from "./NewCourt"

const useStyle = makeStyles(theme => ({
  root: {
    width: 250,
    height: "100%",
  },
}))

function CourtDrawerWrapper({ handleClose, open, content, court }) {
  const classes = useStyle()

  return (
    <Drawer
      variant="temporary"
      anchor="right"
      containerStyle={{ height: "calc(100% + 64px)" }}
      PaperProps={{
        style: { width: 500 },
      }}
      className={classes.root}
      open={open}
      onClose={handleClose}
    >
      <div style={{ height: 1000 }}>
        {content === "NewCourt" && (
          <NewCourt close={handleClose} court={court} />
        )}
        {content === "EditCourt" && <EditCourt close={handleClose} />}
      </div>
    </Drawer>
  )
}

export default CourtDrawerWrapper
